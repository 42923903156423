import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import NavigationItems from '@/components/DesignSystem/NavigationItems';
import { getNavigationPagesList, toggleRfqFormModal } from '@/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import ReactHamburger from '@/components/Common/ReactHamburger';
import { useRef } from 'react';
import { commonOutsideClick } from '@/utils/isClickOutside';
import LogoutModal from '@/components/LogoutModal';
import getOrdersDropdownList from '../../../../utils/orderDropdownList';
import { deviceWidth } from '@/utils/deviceWidth';
import { UserIconDark } from '@/assets/svgExports/UserIconDark';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { ContactUs } from '@/assets/svgExports/ContactUs';
import { HeaderShoppingCart } from '@/assets/svgExports/HeaderShoppingCart';
import { HeaderArrow } from '@/assets/svgExports/HeaderArrow';
import { getStoreType } from '@/utils/getStoreType';
import { ORDERS_DROPDOWN_LIST, STORE_CUSTOMIZATION } from '@/utils/constants';
import { executeLogout } from '@/utils/excuteLogout';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { useSSRSelector } from '@/redux/ssrStore';
import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import useInstallAppOverlayActions from '@/components/InstallAppInstructionsOverlay/useInstallAppOverlayActions';
import { redirectToHomePage } from '@/utils/handleRedirectToHomePage';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import checkIfWebview from '@/utils/checkIfWebview';
import { showMyAccount } from '@/utils/getNavList';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';
import { getWhatAppRedirectionUrlHelp } from '@/utils/getWhatsAppRedirectionUrl';

const InstallAppInstructionsOverlay = dynamic(
  () => import('@/components/InstallAppInstructionsOverlay'),
  {
    ssr: false,
  }
);

const Header = (props) => {
  const { showCategories = false } = props;
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { openIntallAppOverlay } = useInstallAppOverlayActions();
  const isWebview = checkIfWebview();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showDesktopDropdown, setShowDesktopDropdown] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);

  const { items, user } = useSelector((state) => ({
    items: state.cartReducer.items,
    user: state.userReducer,
  }));

  const { storeData, navigationList, catalogCategories, productTags } = useSSRSelector(
    (state) => ({
      storeData: state.storeReducer.store,
      navigationList: state.additionalPagesReducer.navigationList,
      catalogCategories: state.catalogReducer.categories,
      productTags: state.catalogReducer.productTags,
    })
  );

  const ref = useRef();
  const headerRef = useRef();
  useSaveHeaderHeight(headerRef);
  const { visitorLoginFeatureStatus, installAppFeatureStatus } = useFeatureLocks();
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;
  const storeLogo = getActualOrDsStoreLogo(storeData);
  const { storePhone } = useStoreContactDetails();

  const IconList = [
    {
      name: 'Search',
      imgSrc: '/assets/icons/search.svg',
      href: PATH.SEARCH,
    },
    {
      name: 'Contact Us',
      imgSrc: '/assets/icons/whatsapp.svg',
      href: false,
    },
    {
      name: 'Cart',
      imgSrc: '/assets/icons/shopping-cart.svg',
      href: PATH.CART,
    },
    {
      name: 'orders',
      imgSrc: '/assets/icons/user.svg',
      href: PATH.ORDERS,
    },
  ];

  const TempIconList = [
    {
      name: 'Contact Us',
      imgSrc: '/assets/icons/whatsapp.svg',
      href: false,
    },
  ];

  const ordersDropdownList = getOrdersDropdownList();
  const isDesktop = deviceWidth > 766 ? true : false;
  if (isDesktop) IconList.splice(1, 1);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    const callback = () => setShowDesktopDropdown(false);
    commonOutsideClick({ event: e, pathNo: 1, ref, callback });
  };

  useEffect(() => {
    setOpen(false);
    setShowLogoutConfirmation(false);
  }, [user.isLogin]);

  useEffect(() => {
    if (storeData?.store_id && !navigationList?.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id, navigationList]);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }
  }, [isOpen]);

  const onHandlePageClick = (href) => {
    if (href === PATH.CART && getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    if (user?.isLogin && href === PATH.ORDERS && isDesktop) {
      toggleShowDesktopDropdown();
    } else if (href) {
      router.push(getRoute(href, storeData?.store_info?.domain));
    } else {
      window.open(getWhatAppRedirectionUrlHelp());
    }
  };

  const onHandleOrdersDropdown = (item, e = null) => {
    switch (item.action) {
      case ORDERS_DROPDOWN_LIST.MY_ORDERS: {
        toggleShowDesktopDropdown();
        router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
        break;
      }
      case ORDERS_DROPDOWN_LIST.LOGOUT: {
        toggleLogoutModal();
        break;
      }
      case ORDERS_DROPDOWN_LIST.DEFAULT_PHONE: {
        break;
      }
      default: {
        break;
      }
    }
  };

  const toggleAuthModal = () => {
    if (user?.isLogin) {
      toggleLogoutModal();
    } else {
      router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
    }
  };

  function toggleShowDesktopDropdown() {
    setShowDesktopDropdown(!showDesktopDropdown);
  }

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function renderHeaderIcons(icon) {
    switch (icon.name) {
      case 'Search': {
        return (
          <span onClick={() => onHandlePageClick(icon.href)} className="pointer mr30px">
            <SearchIcon width="15" height="15" color={storeData?.theme?.primary_color} />
          </span>
        );
      }
      case 'Contact Us': {
        return (
          <span onClick={() => onHandlePageClick(icon.href)} className="pointer mr30px">
            <ContactUs width="15" height="15" color={storeData?.theme?.primary_color} />
          </span>
        );
      }
      case 'Cart': {
        return (
          <span onClick={() => onHandlePageClick(icon.href)} className="pointer mr30px">
            <HeaderShoppingCart
              width={15}
              height={15}
              color={storeData?.theme?.primary_color}
            />
          </span>
        );
      }
      case 'orders': {
        return (
          <span onClick={() => onHandlePageClick(icon.href)} className="pointer mr6px">
            <UserIconDark
              width="22"
              height="22"
              color={storeData?.theme?.primary_color}
            />
          </span>
        );
      }
    }
  }

  function renderMobileLoginLogout() {
    return (
      <>
        <div
          className={
            'mobile-login-navigation-container flex ' + (user?.isLogin ? 'red' : null)
          }
          onClick={toggleAuthModal}
        >
          {!isDesktop && (
            <div className="mr10px">
              <UserIconDark
                color={
                  storeData?.theme?.colors?.primary_color !== '#ffffff'
                    ? '#000000'
                    : '#ffffff'
                }
                width="18"
                height="18"
              />
            </div>
          )}
          <div style={{ marginTop: 1, marginLeft: 5 }}>
            {user?.isLogin ? 'Logout' : 'Login'}
          </div>
        </div>
      </>
    );
  }

  function renderCustomNavigationItems() {
    return TempIconList.map((icon) => {
      const showIcon = icon.name !== 'Contact Us' || !!storePhone;
      return (
        showIcon && (
          <span className={'relative f6 '} key={icon.name}>
            <span className="pointer pr10px" onClick={() => onHandlePageClick(icon.href)}>
              {isDesktop && icon.name !== 'orders' && icon.name}
            </span>
            {isDesktop ? (
              renderHeaderIcons(icon)
            ) : (
              <img src={icon.imgSrc} onClick={() => onHandlePageClick(icon.href)} />
            )}
          </span>
        )
      );
    });
  }

  function renderNavigationItems() {
    return IconList.map((icon) => {
      const showIcon = icon.name !== 'Contact Us' || !!storePhone;
      return (
        showIcon && (
          <span className={'relative f6 '} key={icon.name}>
            <span className="pointer pr10px" onClick={() => onHandlePageClick(icon.href)}>
              {isDesktop && icon.name !== 'orders' && icon.name}
            </span>
            {isDesktop ? (
              renderHeaderIcons(icon)
            ) : (
              <img
                src={icon.imgSrc}
                onClick={() => onHandlePageClick(icon.href)}
                style={
                  icon.name === 'search'
                    ? {
                        filter: 'brightness(0)',
                      }
                    : {}
                }
              />
            )}
            {isDesktop && (
              <div
                className={
                  'br2 absolute z-5 header-8-orderdropdown-container' +
                  (isVisitorLoginVisible ? '' : '-nologin')
                }
              >
                {showDesktopDropdown &&
                  icon.name === 'orders' &&
                  ordersDropdownList.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        {item.id === 1 && (
                          <div className="header-8-orderdropdown-container-arrow"></div>
                        )}
                        <div
                          className={
                            'header-8-orderdropdown-item flex items-center z-5 ' +
                            (item.id === 3 ? 'red ' : '') +
                            (item.id !== 1 && 'pointer')
                          }
                          onClick={(e) => onHandleOrdersDropdown(item, e)}
                        >
                          {item.id === 1 && (
                            <div className="mr3 mt1">
                              <UserIconDark
                                color={storeData?.theme?.primary_color}
                                width="33"
                                height="33"
                              />
                            </div>
                          )}

                          {item.name}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            )}
            {icon.name === 'Cart' && items?.length > 0 && (
              <span className="cart-item-length-comp absolute">{items.length}</span>
            )}
          </span>
        )
      );
    });
  }

  return (
    <div ref={headerRef} className="Header8">
      <div className="Header8__inner">
        <div className="Header8__inner--subsection">
          <div className="Header8__desktop Header8__name-logo-container">
            {storeLogo && getVisibilityForHeader(storeData?.theme?.store_logo_status) && (
              <img
                className="store-logo pointer"
                src={storeLogo}
                alt="store logo"
                onClick={() => {
                  redirectToHomePage(PATH.PRODUCT, storeData?.domain);
                }}
              />
            )}
            {getVisibilityForHeader(storeData?.theme?.store_name_status) && (
              <span className="store-name pointer">
                <span
                  onClick={() => {
                    redirectToHomePage(PATH.PRODUCT, storeData?.domain);
                  }}
                >
                  {storeData?.store_info?.name}
                </span>
              </span>
            )}
          </div>
          <div className="Header8__mobile" style={{ marginLeft: -10 }}>
            <div>
              <ReactHamburger open={isOpen} setOpen={setOpen} theme="#000000" />
            </div>
          </div>
          <div className="Header8__desktop">
            {navigationList?.length > 0 && (
              <NavigationItems
                categories={[...productTags, ...catalogCategories] || []}
                navigationItems={navigationList || []}
                showCategories={showCategories}
                toggleMegaMenu={() => setShowMegaMenu(!showMegaMenu)}
              />
            )}
          </div>
        </div>
        <div ref={ref} className="Header8__inner--subsection">
          {showCategories ? renderCustomNavigationItems() : renderNavigationItems()}
          {isDesktop && !showCategories && user?.isLogin && (
            <div
              className={
                'header-8-arrowsvg pointer ' + (showDesktopDropdown ? '' : 'rotate-180')
              }
              onClick={toggleShowDesktopDropdown}
            >
              <HeaderArrow
                color={storeData?.theme?.primary_color}
                width="10"
                height="10"
              />
            </div>
          )}
        </div>
      </div>
      <MegaMenuDesktopView open={showMegaMenu} onClose={() => setShowMegaMenu(false)} />
      {isOpen && (
        <div className={`Header8__mobile navigation__container`}>
          <div className="navigation__container--inner">
            <NavigationItems
              categories={[...productTags, ...catalogCategories] || []}
              navigationItems={navigationList || []}
              closeNavigationOnMobile={() => setOpen(false)}
              showCategories={showCategories}
            />
            {!isWebview && !showCategories && installAppFeatureStatus?.isVisible && (
              <span
                className="pwa:tw-hidden"
                onClick={() => {
                  openIntallAppOverlay();
                }}
              >
                <div style={{ marginLeft: 32, marginBottom: 30 }}>Install App</div>
              </span>
            )}
            {isVisitorLoginVisible &&
              !showCategories &&
              !showMyAccount() &&
              renderMobileLoginLogout()}
          </div>
        </div>
      )}
      <InstallAppInstructionsOverlay />
      {!showCategories && showLogoutConfirmation && (
        <LogoutModal
          toggleLogoutModal={toggleLogoutModal}
          executeLogout={executeLogout}
          showLogoutConfirmation={showLogoutConfirmation}
        />
      )}
    </div>
  );
};

export default Header;
